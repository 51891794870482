.features {
	.header {
		padding-top: 6.25rem;

		@include media-breakpoint-down(md) {
			padding-top: 2rem;
		}

		.title {
			font-size: 3rem;
			font-family: 'StagSans';
			font-weight: 300;
	
			strong {
				font-weight: 500;
			}

			@include media-breakpoint-down(xxl) {
				font-size: 2.5rem;
			}

			@include media-breakpoint-down(xl) {
				font-size: 2.25rem;
			}
		}

		.desc {
			font-size: 1.25rem;
			margin-bottom: 2.8125rem;
			opacity: 0.8;
		}
	}

	.features-section {
		.feature {
			padding-top: 6.25rem;
			padding-bottom: 6.25rem;

			@include media-breakpoint-down(md) {
				padding-top: 2rem;
				padding-bottom: 2rem;
			}

			.row {
				> div {
					.feature-img {
						max-width: 450px;
					}

					.features-list {
						h2 {
							font-family: 'StagSans';
							font-weight: 300;

							strong {
								font-weight: 500;
							}
						}
						
						@include media-breakpoint-down(xl) {
							p {
								font-size: 1.125rem !important;
							}
						}
					}
				}
			}
				
			&:nth-child(even) {
				@include media-breakpoint-up(md) {
					background-color: rgba(226,226,226,0.20);
				}

				.row {

					> div {
						.feature-img {
							margin-left: 4.8125rem;

							@include media-breakpoint-down(sm) {
								margin-left: unset;
							}

							.features-list {
								// padding-left: 4.8125rem;

								// @include media-breakpoint-down(md) {
								// 	padding-left: unset;
								// }
							}
						}
					}
				}
			}

			&:nth-child(odd) {
				@include media-breakpoint-down(md) {
					background-color: rgba(226,226,226,0.20);
				}

				.row {
					> div {
						.feature-img {
							margin-right: 4.8125rem;

							@include media-breakpoint-down(sm) {
								margin-right: unset;
							}
						}

						.features-list {
							// padding-right: 4.8125rem;

							// @include media-breakpoint-down(md) {
							// 	padding-right: unset;
							// }
						}
					}
				}
			}
		}
	}
}
