.navbar {
    &-brand {
        span {
            color: transparent;
            left: 0;
        }
    }

    &-nav {
        @include media-breakpoint-down(md) {
            a.btn {
                margin: 0 0 1rem !important;
                width: 100%;
            }
        }
    }
}