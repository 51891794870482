.flex {
  &-1 {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
  }

  &-3 {
    flex-grow: 3;
    flex-shrink: 1;
    flex-basis: 0;
  }
}