@use "sass:math";

@font-face {
	font-family: 'StagSans';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url(./../fonts/StagSans-Semibold.woff) format('woff');
}

@font-face {
	font-family: 'StagSans';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url(./../fonts/StagSans-Medium.woff) format('woff');
}

@font-face {
	font-family: 'StagSans';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(./../fonts/StagSans-Book.woff) format('woff');
}

@font-face {
	font-family: 'StagSans';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url(./../fonts/StagSans-Light.woff) format('woff');
}

$base: 16 !default;
$util-font-sizes: 10, 14, 15, 16, 18, 19, 20, 22, 23, 24, 27, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200;
@each $util-font-size in $util-font-sizes {
	.fs-#{$util-font-size} {
		font-size: math.div($util-font-size, $base)+rem !important;
	}
}

$util-font-weights: 300, 400, 500, 600, 700, 900;
@each $util-font-weight in $util-font-weights {
	.fw-#{$util-font-weight} {
		font-weight: $util-font-weight !important;
	}
}

$util-line-heights: 12000, 13000, 9475;
@each $util-line-height in $util-line-heights {
	.lh-#{$util-line-height} {
		line-height: math.div($util-line-height, 10000) !important;
	}
}