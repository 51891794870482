// Body
$body-bg: #ffffff;
$body-color: #3E3E3E;
$primary: #002653;
// $primary: #0093d7;
$secondary: #1F86FF;
// $secondary: #ff8f2f;
$theme-colors: (
		"primary": $primary,
		"secondary": $secondary,
		"dark": $body-color
);

// Typography
$font-family-sans-serif: 'DM Sans', sans-serif;
$btn-font-family: $font-family-sans-serif;
$btn-font-size: 1.1875rem;
$font-weight-bold: 600;

// Colors
$white: #ffffff;
$black: #000000;
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

// Spacing
$spacer: 1rem !default;
$spacers: (
		0: 0,
		1: $spacer * .25,
		2: $spacer * .5,
		3: $spacer,
		4: $spacer * 1.5,
		5: $spacer * 3,
		50: $spacer * 3.125,
);

// scss-docs-start container-max-widths
$container-max-widths: (
		sm: 540px,
		md: 720px,
		lg: 960px,
		xl: 1140px,
		xxl: 1364px
) !default;
// scss-docs-end container-max-widths

// Container padding
//$container-padding-x: 0 !default;

// Navbar
$navbar-nav-link-padding-x: $spacer * 1;
$nav-link-font-weight: 500;
$navbar-light-color: $body-color;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg'  width='41.021' height='30' viewBox='0 0 41.021 30'><path d='M680,39.811H641.979' transform='translate(-640.479 -38.311)' fill='none' stroke='#005a8b' stroke-linecap='round' stroke-width='3'/><path d='M680,39.811H641.979' transform='translate(-640.479 -29)' fill='none' stroke='#005a8b' stroke-linecap='round' stroke-width='3'/><path d='M660.99,39.811h-30.01' transform='translate(-629.5 -20)' fill='none' stroke='#005a8b' stroke-linecap='round' stroke-width='3'/><path d='M660.99,39.811h-19.01' transform='translate(-640.479 -11.25)' fill='none' stroke='#005a8b' stroke-linecap='round' stroke-width='3'/></svg>") !default;

// Inputs
$input-border-color: #e5e5e5;
$input-focus-border-color: #e5e5e5;
$input-color: $black;
$input-border-radius: 5px;
$input-font-size: 18px;
$input-font-weight: 300;
$input-line-height: 1.78;
$input-btn-padding-y: .5rem;
$input-focus-box-shadow: none;
