@import "utilities/fonts";
@import "variables";
@import "utilities/functions";
@import "utilities/dimens";
@import "utilities/display";
@import "~bootstrap/scss/bootstrap";

$flagsImagePath: '../images/icons/';
@import '~intl-tel-input/build/css/intlTelInput.min.css';

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

@import "layouts/features";
@import "layouts/footer";
@import "layouts/navbar";

@import "general";
@import "auth";
@import "pricing";
@import "features";
@import "home";
@import "contact";
