.auth {
	@include media-breakpoint-down(md) {
		&.vh-100 {
			height: unset !important;
			min-height: 100vh !important;
		}

		#app {
			min-height: 100vh;
		}
	}

	.auth-content {
		.key-1 {
			position: absolute;
			top: 0;
			left: -100px;
			bottom: 0;
			right: 50%;
			z-index: -1;
			background-image: url(/images/key-1.svg);
			background-repeat: no-repeat;
			height: 100%;
			overflow: hidden;

			@include media-breakpoint-down(lg) {
				display: none;
			}
		}

		.key-2 {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 50%;
			z-index: -1;
			background-image: url(/images/key-2.svg);
			background-repeat: no-repeat;
			background-size: cover;
			height: 100%;
			overflow: hidden;

			@include media-breakpoint-down(lg) {
				display: none;
			}
		}

		.auth-text {
			font-size: 2.8125rem;
			font-weight: 300;
			// font-style: normal;
			// letter-spacing: normal;
			// font-family: $font-family-sans-serif;

			strong {
				font-weight: 700;
			}

			@include media-breakpoint-down(xxl) {
				font-size: 2.125rem;
			}

			@include media-breakpoint-down(md) {
				font-size: 38px;
				font-weight: 300;
				line-height: 41px;
				margin: 2.125rem 0 2.5625rem;
			}
		}

		.register {
			&-body {
				.mb-2 {
					margin-bottom: 10px !important;
				}

				@include media-breakpoint-down(md) {
					margin-bottom: 4.625rem;
				}

				.step {
					box-shadow: 0 20px 22px rgba(0, 0, 0, 0.15);
					border-radius: 1.5rem;
					padding: 35px 55px;
					min-width: 600px;

					@include media-breakpoint-down(md) {
						padding: 30px 15px;
						min-width: unset;
					}

					&-4 {
						box-shadow: unset;
						border-radius: none;
					}
				}

				.form-title {
					font-size: 2.125rem;
					font-weight: 500;
					color: $secondary;
				}

				.policy {
					color: #c1c1c1;
					font-size: 16px;
					font-weight: 300;
					line-height: 18px;
					text-align: left;
					font-family: $font-family-sans-serif;

					a {
						text-decoration: underline;
					}

					@include media-breakpoint-down(md) {
						font-size: 14px;
					}
				}


				.btn-primary {
					height: 50px;
				}

				.indicator {
					border-radius: 50%;
					width: 16px;
					height: 16px;
					border: 1px solid #c1c1c1;
					cursor: pointer;

					&:not(:last-of-type) {
						margin-right: 10px;
					}
				}

				.lottie {
					height: 300px !important;
				}

				.build {
					.auth-text {
						margin-bottom: 40px;
					}

					&-title {
						color: $body-color;
						font-family: $font-family-sans-serif;
						font-size: 28px;
						font-weight: 300;
						line-height: 30px;

						@include media-breakpoint-down(md) {
							font-size: 16px;
							line-height: 12px;
						}
					}
				}
			}
		}

		.login {

			.logo {
				margin-bottom: 80px;

				@include media-breakpoint-down(sm) {
					margin-bottom: unset;
				}
			}

			&-intro {
				color: $body-color;
				font-size: 18px;
				font-weight: 300;
				font-family: $font-family-sans-serif;
				line-height: 32px;
			}

			form {
				margin: 80px 0;

				@include media-breakpoint-down(md) {
					margin: 70px 0;
				}
			}

			.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
				text-align: center;
			}

			.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
				text-align: center;
			}

			.form-control::-ms-input-placeholder { /* Microsoft Edge */
				text-align: center;
			}

			&-text {
				color: #005a8b;
				font-family: $font-family-sans-serif;
				font-size: 16px;
				font-weight: 300;
				line-height: 32px;
			}

			&-link {
				font-family: $font-family-sans-serif;
				font-size: 16px;
				font-weight: 300;
				line-height: 32px;
				color: #ff8f2f;
				text-decoration: underline;
			}
		}
	}

	.pc {
		@include media-breakpoint-down(md) {
			margin-bottom: 5.25rem;
		}
	}

	.input-group {
		.form-control {
			border-radius: 5px 0 0 5px !important;
		}
	}

	.active {
		border: 1px solid $secondary;
		background-color: $secondary;
	}

	.is-danger {
		border: 1px solid #e3342f !important;
	}

	.multiselect__tags {
		min-height: 50px;
		padding: 14px 40px 0 14px;
	}

	.multiselect__select {
		height: 46px;
	}

	.multiselect__single {
		font-size: 18px;
	}

	.multiselect__placeholder {
		font-size: 18px;
	}
}

.form-control {
	@include media-breakpoint-down(md) {
		font-size: 16px;
		height: 45px !important;
	}
}

@include media-breakpoint-down(md) {
	.form-control {
		font-size: 16px;
		height: 45px !important;
	}

	.input-group {
		.input-group-text {
			height: 45px !important;
		}
	}
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #a5a5a5;
	font-size: 18px;
	font-weight: 300;
	// line-height: 32px;
	font-family: $font-family-sans-serif;

	@include media-breakpoint-down(md) {
		font-size: 16px;
	}
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #a5a5a5;
	font-family: "Stag Sans";
	font-size: 18px;
	font-weight: 300;
	// line-height: 32px;
	font-family: $font-family-sans-serif;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
	color: #a5a5a5;
	font-family: "Stag Sans";
	font-size: 18px;
	font-weight: 300;
	line-height: 32px;
	font-family: $font-family-sans-serif;
}

.form-check-input {
	@include media-breakpoint-down(md) {
		display: flex;
		align-self: start;
		width: 1.25rem !important;
		height: 1.25rem !important;
	}
}

.select-input {
	.multiselect__tags {
		font-size: 18px;
	}

	.multiselect__input, .multiselect__single {
		font-size: 18px;
		padding: 6px 0;
	}
}

.btn:focus {
	box-shadow: unset !important;
}

.subscription {
	display: flex;

	@include media-breakpoint-down(md) {
		flex-direction: column;

		.pb-3 {
			padding-bottom: 10px !important;
		}

		.pt-4 {
			padding-top: 10px !important;
		}
	}

	&-item {
		border-radius: 5px;
		border: 1px solid transparent;
		box-shadow: 0 0 0 2px #ff8f2f;
		padding: 30px 15px;
		min-width: 318px;

		@include media-breakpoint-down(md) {
			padding: 18px 15px;

			&:first-of-type {
				margin-bottom: 18px;
			}
		}

		.duration {
			height: 32px;

		}

		button.save {
			background-color: #ff8f2f;
			border-color: #ff8f2f;
		}

		// &:hover {
		// 	box-shadow: 0 0 0 2.6px #0093d7;
		// 	border-color: transparent;

		// 	small, strong, .duration {
		// 		color: #0093d7;
		// 	}
		// }

		&:first-of-type {
			margin-right: 20px;
			box-shadow: 0 0 0 2px #0093d7;
			border-color: transparent;

			@include media-breakpoint-down(md) {
				margin-right: unset;
			}
		}

		p, span {
			font-size: 18px;
			font-weight: 300;
			line-height: 18px;
			font-family: $font-family-sans-serif;

			@include media-breakpoint-down(md) {
				font-size: 16px;
				line-height: 22px;
			}
		}

		small {
			font-size: 26px;
			color: $body-color;
			font-weight: 600;
			font-family: $font-family-sans-serif;

			&:first-of-type {
				padding-right: 5px;
			}

			@include media-breakpoint-down(md) {
				font-size: 22px;
			}
		}

		strong {
			font-size: 34px;
			font-weight: 600;
			font-family: $font-family-sans-serif;
			color: $body-color;

			@include media-breakpoint-down(md) {
				font-size: 27px;
			}
		}

		.btn-select {
			font-size: 12px;
			font-weight: 600;
			line-height: 16px;
			border-radius: 16px;
			border: 1px solid $secondary;
			background-color: $secondary;
			height: 32px;
			display: flex;
			align-items: center;
			white-space: nowrap;

			@include media-breakpoint-down(md) {
				font-size: 13px;
			}
		}

		.btn-trial {
			background-color: #00be42;
			padding: 10px 0;
			font-size: 19px;
			font-weight: 500;
			margin-bottom: 18px;

			@include media-breakpoint-down(md) {
				font-size: 16px;
				margin-bottom: 15px;
			}
		}

		.text-gray {
			color: #c1c1c1;
			font-size: 13px;
			font-weight: 300;
			font-family: $font-family-sans-serif;
		}

		.form-select {
			width: 30% !important;
			font-size: 20px;
			font-weight: 300;
			line-height: 32px;
			border-radius: 5px;
			border: 1px solid $body-color;
			padding: 0.375rem 0.75rem 0.375rem 0.75rem;
			background-image: none;

			&:focus {
				box-shadow: unset;
			}

			@include media-breakpoint-down(md) {
				font-size: 18px;
				line-height: 28px;
			}
		}

		.item {
			border-bottom: 1px solid #e5e5e5;
		}
	}
}

.iti {
	width: 100%;
}