.home {
	.header {
		.title {
			font-size: 4rem;
			font-family: 'StagSans';
			font-weight: 300;

			strong {
				font-weight: 500;
			}

			@include media-breakpoint-down(xxl) {
				font-size: 2.3125rem;
			}

			@include media-breakpoint-down(lg) {
				margin-bottom: 1rem;
			}

			@include media-breakpoint-down(md) {
				margin-bottom: 0;
			}
		}

		.desc {
			font-size: 1.5rem;
			margin-bottom: 2.8125rem;
			opacity: 0.8;
			
			@include media-breakpoint-down(xl) {
				font-size: 1.25rem;
			}
		}

		.banner-section {
			padding-top: 3.125rem;
			padding-bottom: 3.125rem;
		}
	}

	.title {
		margin-top: 1.875rem;
		margin-bottom: 1.875rem;
		font-size: 2.625rem;
		font-family: 'StagSans';
		font-weight: 300;

		strong {
			font-weight: 500;
		}

		@include media-breakpoint-down(xxl) {
			font-size: 2.3125rem !important;
		}

		@include media-breakpoint-down(xl) {
			font-size: 1.75rem !important;
		}

		@include media-breakpoint-down(md) {
			margin-bottom: 1.125rem;
			margin-top: 0.9375rem;
		}
	}

	.desc {
		font-size: 1.5rem;
		margin-bottom: 2.3125rem;
		opacity: 0.8;

		@include media-breakpoint-down(xxl) {
			font-size: 1.25rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 1rem;
			margin-top: 1.125rem;
			margin-bottom: 1.875rem;
		}
	}

	@include media-breakpoint-down(md) {
		a.btn {
			margin-bottom: 1rem;
		}
	}

	.banner-image {
		@include media-breakpoint-down(md) {
			margin-bottom: 2rem;
		}
	}

	.what-is-mykii {
		padding-top: 6.25rem;
		padding-bottom: 6.25rem;
		
		@include media-breakpoint-down(md) {
			padding-top: 0;
			padding-bottom: 2rem;
		}
	}

	.connect-your-website {
		background-color: rgba(226,226,226,0.20);
		padding-top: 6.25rem;
		padding-bottom: 6.25rem;
		
		@include media-breakpoint-down(md) {
			padding-top: 2rem;
			padding-bottom: 2rem;
		}
	}

	.stay-agile {
		padding-top: 6.25rem;
		padding-bottom: 6.25rem;
		
		@include media-breakpoint-down(md) {
			padding-top: 2rem;
			padding-bottom: 2rem;
		}
	}

	.features {
		padding-top: 6.25rem;
		padding-bottom: 6.25rem;
		background-color: rgba(226,226,226,0.20);
		
		@include media-breakpoint-down(md) {
			padding-top: 2rem;
			padding-bottom: 2rem;
		}

		.text-primary {
			margin-bottom: 1.875rem;
		}

		// .img-fluid.features-img {
		// 	max-width: unset;

		// 	@include media-breakpoint-down(xxl) {
		// 		max-width: 100%;
		// 		width: unset;
		// 	}
		// }

		@include media-breakpoint-down(xxl) {
			.list-unstyled {
				li.mb-4 {
					margin-bottom: 1rem !important;
				}

				li.fs-24 {
					font-size: 1.2rem !important;
				}
			}
		}

		@include media-breakpoint-down(md) {
			padding-top: 3rem;

			.text-primary {
				font-size: 0.875rem;
				margin-bottom: 1.1875rem;
			}

			// .title {
			// 	font-size: 2.3125rem;
			// 	margin-bottom: 2.375rem;
			// }

			.list-unstyled {
				li {
					font-size: 1rem !important;
					margin-bottom: 1.3125rem !important;

					img {
						width: 1.3125rem !important;
					}
				}
			}

			.features-img {
				width: 100%;
				margin-top: 3rem;
			}
		}
	}
}
