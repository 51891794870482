.navbar-light {
	.navbar-toggler {
		border-color: unset !important;
		border: none !important;

		&:focus {
			box-shadow: unset !important;
		}
	}
}

.h-80 {
	height: 80% !important;
}

.w-49 {
	width: 49%;
}

body, html {
	margin: 0;
	padding: 0;
	overflow-x: hidden;
}

* {
	box-sizing: border-box;
}

.pre-title {
	font-family: "StagSans";
	font-weight: 300;
	color: $secondary;
	font-size: 1.125rem;
	letter-spacing: 0.075rem;
	text-transform: uppercase;
}

.page-title {
	color: $secondary;
	font-size: 14px;
	line-height: 26px;
	text-transform: uppercase;
}

.btn {
	white-space: nowrap;
	border-radius: 3px;
	border-width: 2px;
}

.btn-outline-primary {
  color: $primary !important;
}

.btn-outline-primary:hover {
  color: #ffffff !important;
}

.btn-outline-secondary {
  color: $secondary !important;
}

.btn-outline-secondary:hover {
  color: #ffffff !important;
}

a {
	color: $secondary;
}
