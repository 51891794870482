.contact {
	padding-top: 11.25rem;
	padding-bottom: 11.3125rem;

	@include media-breakpoint-up(xl) {
		min-height: calc(100vh - (77px + .3125rem * 2 + 1.125rem));
	}

	@include media-breakpoint-between(md, xl) {
		padding-top: 9rem;
		padding-bottom: 9rem;
	}

	@include media-breakpoint-down(md) {
		padding-top: 4.375rem;
		padding-bottom: 3.125rem;
	}

	@include media-breakpoint-down(sm) {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}

	.title {
		font-size: 4.375rem;
		margin-top: 1.9375rem;
		margin-bottom: 1.75rem;

		@include media-breakpoint-down(xxl) {
			font-size: 3rem;
		}

		@include media-breakpoint-down(xl) {
			font-size: 3rem;
			margin-bottom: 1.8rem;
		}

		@include media-breakpoint-down(md) {
			font-size: 2.375rem;
			line-height: 41px;
			margin-top: 0.8125rem;
			margin-bottom: 1.8rem;
		}
	}

	.desc {
		font-size: 1.5rem;

		@include media-breakpoint-down(xl) {
			font-size: 1.25rem;
			margin-bottom: 3.0625rem;
		}

		@include media-breakpoint-down(md) {
			margin-bottom: 3.4375rem;
		}
	}

	.contact-card {
		border-radius: 15px;
		box-shadow: 0 20px 22px rgba(0, 0, 0, 0.15);
		z-index: 1;

		.card-body {
			padding: 2.0625rem 3.4375rem;

			@include media-breakpoint-down(md) {
				padding: 1.75rem 0.9375rem;
			}

			h3 {
				color: $secondary;
				font-size: 2.125rem;
			}

			.terms-link {
				color: #c1c1c1;

				a {
					color: $secondary;
				}
			}
		}
	}
}
