.register-buttons {
  .btn {
    padding-left: 3.5625rem;
    padding-right: 3.5625rem;

    @include media-breakpoint-down(md) {
      padding-left: 2.1875rem;
      padding-right: 2.1875rem;
    }
  }
}

.footer-header {
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;

  @include media-breakpoint-down(md) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .heading {
    line-height: 1.625;
    margin-bottom: 1.8125rem;
    font-size: 1.5rem;

    @include media-breakpoint-down(md) {
      font-size: 0.875rem;
      margin-bottom: 1.25rem;
    }
  }

  .sub-heading {
    font-size: 3.5625rem;
    margin-bottom: 3.75rem;
    text-align: center;
    font-family: "StagSans";
    font-weight: 400;

    @include media-breakpoint-down(xxl) {
      font-size: 2.3125rem;
    }

    @include media-breakpoint-down(md) {
      font-size: 2.3125rem;
      margin-bottom: 3.125rem;
    }
  }

  .steps {
    margin-bottom: 5.125rem;
    max-width: 720px;
    align-items: center;
    width: 100%;

    @include media-breakpoint-between(md, xl) {
      justify-content: center;
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
      justify-content: center;
    }

    .item {
      flex: 1 1 33.3334%;

      &:not(:last-child) {
        // padding-right: 4.125rem;
        border-right: 2px solid rgba(0, 90, 139, 0.5);

        @include media-breakpoint-down(xl) {
          border-right: unset;
        }

        @include media-breakpoint-down(lg) {
          // padding-right: 1.5rem;
        }

        @include media-breakpoint-down(md) {
          padding-bottom: 1.5rem;
          border-bottom: 2px solid rgba(0, 90, 139, 0.5);
        }

        @include media-breakpoint-down(sm) {
          // padding-right: 0;
        }
      }

      &:not(:first-child) {
        // padding-left: 4.125rem;

        @include media-breakpoint-between(md, xl) {
          // padding-left: unset;
        }

        @include media-breakpoint-down(md) {
          // padding-left: unset;
          padding-top: 1.5rem;
        }
      }

      button {
        margin-bottom: 1.625rem;
        margin-top: 1.625rem;
        padding-left: 1.6875rem;
        padding-right: 1.6875rem;
      }
    }
  }
}

.footer {
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;
  background-color: $secondary;

  @include media-breakpoint-down(md) {
    padding-top: 3.125rem;
  }

  &-lg {
    @include media-breakpoint-down(xl) {
      display: none;
      padding-top: 3.125rem;
    }
  }

  &-sm {
    display: none;

    @include media-breakpoint-down(xl) {
      display: block;
      margin-bottom: 2.6875rem;
    }

    .policy {
      font-size: 1rem;
    }
  }

  a {
    font-size: 20px;
    font-family: 'StagSans';

    @include media-breakpoint-down(xxl) {
      font-size: 1rem;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 1rem;
    }
  }

  .logo {
    @include media-breakpoint-between(md, xl) {
      margin-bottom: 3rem;
    }

    @include media-breakpoint-down(xl) {
      margin-bottom: 4rem;
    }
  }

  .social-links {
    display: flex;
    justify-content: center;

    @include media-breakpoint-down(md) {
      justify-content: start;
    }

    img {
      &:not(:last-child) {
        margin-right: .75rem;
      }
    }
  }
}
