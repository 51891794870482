.pricing {
	.header {
		padding-bottom: 6.25rem;

		@include media-breakpoint-down(md) {
			padding-bottom: 2rem;
		}
	}

	&-intro {
		max-width: 564px;
		text-align: center;
		padding-top: 3.75rem;

		@include media-breakpoint-down(md) {
			max-width: unset;
			padding-top: 2rem;
		}

		&-title {
			// font-family: $font-family-sans-serif;
			font-size: 3.75rem;
			padding-bottom: 4.5rem;
			
			@include media-breakpoint-down(md) {
				font-size: 2.375rem;
				line-height: 2.5625rem;
				padding-bottom: 2.5rem;
			}
		}
	}

	.subscription {
		box-shadow: 0 20px 22px rgba(0, 0, 0, 0.15);
		border-radius: 15px;
		padding: 2.1875rem 2.3125rem 2.625rem 2.3125rem;

		@include media-breakpoint-down(md) {
			padding: 1.5625rem 1.125rem;
		}

		&-item{

			&:first-of-type {
				.btn-trial {
					background-color: $secondary !important;
				}
			}
			
			.btn-trial {
				background-color: #ff8f2f !important;
			}

			.form-control {
				border: 1px solid #005a8b !important;
				color: #005a8b;
			}
		}
	}

	.features {
		padding-top: 6.25rem;
		padding-bottom: 6.25rem;
		background-color: rgba(226,226,226,0.20);

		.text-primary {
			margin-bottom: 1.875rem;
		}

		.title {
			margin-bottom: 4.625rem;

			@include media-breakpoint-down(xl) {
				margin-bottom: 1.875rem;
			}
		}

		@include media-breakpoint-down(xl) {
			.list-unstyled {
				li {
					font-size: 1.125rem !important;
				}
			}
		}

		@include media-breakpoint-down(md) {
			padding-top: 2rem;
			padding-bottom: 2rem;

			.text-primary {
				font-size: 0.875rem;
				margin-bottom: 1.1875rem;
			}

			.title {
				font-size: 2.3125rem;
				margin-bottom: 2.375rem;
			}

			.list-unstyled {
				li {
					font-size: 1rem !important;
					margin-bottom: 1.3125rem !important;

					img {
						width: 21px !important;
					}
				}
			}

			.features-img {
				width: 100%;
			}
		}
	}
}
